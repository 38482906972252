@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #2a2185;
  --white: #fff;
  --gray: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}



.datatable-style-demo .outofstock {
  font-weight: 700;
  color: #FF5252;
}

.datatable-style-demo .lowstock {
  font-weight: 700;
  color: #FFA726;
}

.datatable-style-demo .instock {
  font-weight: 700;
  color: #66BB6A;
}

.datatable-style-demo .row-accessories {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
   